.divider{
    color:#7b809a !important; 
    height: unset !important; 
    border-color: #d2d6da !important; 
    border-width: 1px !important; 
    background-image: none !important; 
    opacity: 1 !important; 
    margin-bottom: 0 !important;
}

.divider::after{
    border-color: #d2d6da !important; 
    border-width: 1px !important; 
}

.divider::before{
    border-color: #d2d6da !important; 
    border-width: 1px !important; 
}