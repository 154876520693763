.App {
    background-color: #eee;
    position: relative;
    padding-bottom: 50px;
  }
  .list-container {
    display: flex;
    font-size: 18px;
    height: calc(100% - 50px);
    width: 100%;
    background-color: #eee;
    flex-direction: column;
  }
  .item-container {
    background-color: #fff;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 10px;
    padding: 5px 20px;
    margin: 15px 50px;
    display: 'flex';
    justify-content: space-between;
    align-items: center;
  }