.inpCont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error{
    font-size: 12px;
    color: red;
}