.dividerText{
    font-weight: bolder;
    font-size: 20px;
    color: #344767;
}

.dividerTextSm{
    font-weight: bolder;
    font-size: 16px;
    color: #344767;
}

.dividerClass{
    margin: 40px 0px 0px 5px;
}

.flex{
    display: flex;
    justify-content: center;
    align-items:  center;
    gap: 10px;
}

.header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}