.nameBtn{
    position: absolute !important;
    top: -25px;
    left: 10px;
}

.settings{
    position: absolute;
    top: -20px; 
    right: 10px;
    display: flex;
    gap: 10px
}

.tabs{
    margin-top: 0px;
}

.pieCharts{ 
    display: flex;
    flex-direction: row 
}

.infoCont{
    display: unset;
}

.actionBtn{
    width: fit-content
}

@media screen and (max-width: 520px) {
    .nameBtn{
        position: absolute;
        top: -30px;
        left: 0px;
        display: flex;
        justify-content: space-between;
        width: 100%
    }
    
    .settings{
        position: absolute;
        top: 25px; 
        right: 10px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        width: 100%
    }
    .tabs{
        margin-top: 50px;
    }
    /* .infoCont{
        display: none;
    } */
    .actionBtn{
        width: 70%
    }
    .pieCharts{ 
        display: flex;
        flex-direction: column 
    }
}