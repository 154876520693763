.inputsCont {
    display: flex;
    flex-direction: column !important; 
    height: 220px; 
    overflow: auto;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}

.inputsCont input{
    min-height: 20px;
    padding: 0px 10px;
    width: 400px;
    border: 1px solid transparent;    
}

.inputCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 26px;
    /* border: 1px solid lightgray; */
}

.priceSym{
    font-size: 15px !important;
    padding: 0 !important;
}

.loadingOverlay{
    background-color: rgba(128, 128, 128, 0.568) !important;
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    z-index: 5000;
}

.seasonsButtons{
    width: 100%;
    display: flex;
    overflow: auto;
    gap: 10px;
    margin: 10px 0px;
    padding: 10px;
}

.hideScrollbar{
    overflow-x: hidden !important
}

.episodesButtons{
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 10px;
}

.episodeButton{
    display: flex;
    gap: 10px;
    margin: 0px 10px;
}

.inputCont:hover .add{
    display: block;
}

.inputCont:hover .index{
    display: none;
}

.toolsLeft{
    width: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.input{
    min-height: 20px;
    padding: 0px 10px;
    width: 400px;
    border: 1px solid transparent; 
}

.delete {
    cursor: pointer;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
}

.add{
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
}