.cont{
    position: relative;
}
.overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 11;
    border-radius: 10px;
    cursor: not-allowed;
}