
.programs{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /* scroll-behavior: smooth; */
}

.programCont{
    padding: 10px;
    display: flex;
    
}

.program{
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progName{
    text-align: center;
}

.time{
    font-size: 18px;
    white-space: nowrap;
    margin-right: 10px;
}

.hasArchive{
    position: relative;
    display: flex;
}

.hasArchive::after{
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    border-radius: 50%;
    background-color: red;
}

.currentProgram{
    color: #4CAF50 !important;
}

.epgCont {
    width: 0px;
    height: 0px;
    transition-duration: 0.2s;
    position: relative;
}

.epgOpener{
    position: absolute;
    right: 0px;
    top: 0px;
}

.divider{
    margin-top: 10px !important
}