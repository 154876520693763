.screen{
    height: auto;
    width: 100%;
    aspect-ratio: 4/5;
    background-image: url("../../../assets/images/background2-potrait.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalView {
    position: relative;
    width: 50%;
    padding-bottom: 40px;
    padding-top: 10px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
}

.header {
    font-size: 12px;
    color: #fff;
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerText {
    color: #fff;
    font-size: 10px;
}

.content {
    font-size: 7px;
    color: #fff;
    width: 100%;
    padding: 20px 20px;
}

.footer {
    position: absolute;
    padding: 0px 20px;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
}

.saveButton {
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 5px;
    cursor: pointer;
    color: #fff;
    background-color: #86c232aa;
}

.cancelText{
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 5px;
    cursor: pointer;
    color: #fff;
}

.closeButton {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: transparent;
}
