/* mozilla */
progress.action::-moz-progress-bar { background: #1A73E8 !important; }
progress.success::-moz-progress-bar { background: #4CAF50 !important; }

/* chrome safari  */
progress.action::-webkit-progress-value { background: #1A73E8 !important; }
progress.success::-webkit-progress-value { background: #4CAF50 !important; }

/* IE10>= */
progress.action { background: #1A73E8 !important; }
progress.success { background: #4CAF50 !important; }

.loader{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgba(211, 211, 211, 0.46);
}

.drawerInfoPanel{
    min-width: 30vw !important;
    width: 500px !important;
    max-width: 89vw !important;
}

.drawerInfoPanelServers{
    min-width: 30vw !important;
    width: 1100px !important;
    max-width: 89vw !important;
}

.serverProcessesHeader{
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 620px) {
    .serverProcessesHeader{
        display: flex;
        flex-direction: column; 
        justify-content: center; 
        align-items: center;
        gap: 10px; 
    }
}