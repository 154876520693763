.focused{
    background-color: gray;
}

.inputsCont {
    display: flex;
    flex-direction: column !important; 
    max-height: 470px; 
    overflow: auto;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}

.inputsCont input{
    min-height: 20px;
    padding: 0px 10px;
    width: 100%;
    border: 1px solid transparent;    
}

.inputCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 26px;
    /* border: 1px solid lightgray; */
}

.inputCont:hover .add{
    display: block;
}

.index{
    font-size: 16px;
}

.inputCont:hover .index{
    display: none;
}

.toolsLeft{
    width: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.input{
    min-height: 20px;
    padding: 0px 10px;
    width: 400px;
    border: 1px solid transparent; 
}

.delete {
    cursor: pointer;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
}

.add{
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
}