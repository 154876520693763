.table{
    width: 100%;
    height: calc(70vh - 62px);
    overflow: auto;
}

.skeleton {
    width: 100%
}

.box{
    height: 31vh;
    position: relative;
}

.switch{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.title{
    font-size: 15px;
}

.label{
    font-size: 15px;
    white-space: nowrap;
    font-weight: normal;
}

.heading{
    width: 100%;
    text-align: center;
    border-radius: 10px;
}